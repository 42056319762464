export default [
    {
        path: '/book-a-property/:mapOrAffiliate?',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.view" */ '@/views/Public/Properties/List.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            label: 'Book a Property',
            multiRoute: 'public.properties',
            floatingMenu: true,
            footerCtaType: 'book',
            metaData: {
                description: 'Luxury vacation rentals. Seamless booking. Browse stylish homes by size, occasion, location & amenities. Book today!',
            },
            
        }
    },

    {
        path: '/properties/:slug/:thirdParty?',
        alias: ['/property/:slug'],
        components: {
            default: () => import(/* webpackChunkName: "public.properties.view" */ '@/views/Public/Properties/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            label: '{slug}',
            footerCtaType: 'book',
            multiRoute: 'public.properties.view',

            scrollBehavior(to, from) {
                if (
                    (to) &&
                    (to.params) &&
                    (to.params.slug) &&
                    (from) &&
                    (from.params) &&
                    (from.params.slug) &&
                    (to.params.slug == from.params.slug)
                ) {
                    return false;
                }

                return null;
            },
        }
    },

    // the preview property is the same as the properties view
    // but with different parameters for access
    {
        path: '/preview-property/:propertyId(\\d+)/:hash?',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.view" */ '@/views/Public/Properties/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            label: 'Preview Property',
            multiRoute: 'public.properties.view',
            footerCtaType: 'book',
            usePreviewEndPoint: true, // specific to this route view only
        }
    },

    {
        path: '/similar-properties/:slug',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.similar" */ '@/views/Public/Properties/Similar.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            label: 'Similar Properties',
            footerCtaType: 'book',
            multiRoute: 'public.properties.similar',
        }
    },

    {
        path: '/book',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.book" */ '@/views/Public/Properties/Book.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            checkOutFlow: true,
            useLastMetaData: true,
            footerCtaType: 'book',
            multiRoute: 'public.properties.book',
        }
    },

    {
        path: '/book-payment',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.book" */ '@/views/Public/Properties/BookPayment.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: true,
            group: '/properties',
            resetBackButton: false,
            checkOutFlow: true,
            useLastMetaData: true,
            footerCtaType: 'book',
            multiRoute: 'public.properties.book.payment',
        }
    },

    {
        path: '/lease/:thirdParty',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.lease" */ '@/views/Public/Properties/Lease.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            checkOutFlow: true,
            useLastMetaData: true,
            footerCtaType: 'book',
            multiRoute: 'public.properties.lease',
        }
    },

    {
        path: '/properties/:propertyId(\\d+)/qr/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.qr" */ '@/views/Public/Properties/QrCode.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            footerCtaType: 'book',
            multiRoute: 'public.properties.qr',
        }
    },

    {
        path: '/properties/:propertyId(\\d+)/add-review',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.review" */ '@/views/Public/Properties/AddReview.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/properties',
            resetBackButton: false,
            footerCtaType: 'book',
            multiRoute: 'public.properties.review',
        }
    },

    {
        path: '/boutique-collections',
        alias: ['/boutique-collection', '/boutique'],
        components: {
            default: () => import(/* webpackChunkName: "public.properties.boutique" */ '@/views/Public/Properties/Collections.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/boutique-collections',
            resetBackButton: false,
            footerCtaType: 'book',
            floatingMenu: true,
            multiRoute: 'public.properties.boutique',
            label: 'Boutique Collections',
        }
    },

    {
        path: '/bungalows',
        components: {
            default: () => import(/* webpackChunkName: "public.properties.bungalows" */ '@/views/Public/Properties/Bungalows.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/boutique-collections',
            resetBackButton: false,
            footerCtaType: 'book',
            floatingMenu: true,
            multiRoute: 'public.properties.bungalows',
            label: 'Beach and Bay Bungalows',
            metaData: {
                description: 'Hear the waves of the Atlantic Ocean crashing against the crisp, white sand. Smell the salt emanating from the silky smooth bay.',
            },
        }
    },
    
  ];
  